<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Add Users'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group label="Tipe User" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="form.userType"
                    :aria-describedby="ariaDescribedby"
                    name="userType"
                  >
                    <b-form-radio value="sso">SSO</b-form-radio>
                    <b-form-radio value="nonsso">NON SSO</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nik_label"
                  label="No Badge :"
                  label-for="nik"
                >
                <!-- Untuk Melakukan Load Users Organik Demplon 
                    =>loadOptionUsers
                    => validateState('nik')
                -->
                  <treeselect
                    size="sm"
                    id="nik"
                    v-model="$v.form.nik.$model"
                    :multiple="false"
                    :clearable="true"
                    :async="true"
                    valueFormat="label"
                    :load-options="loadOptionsUsers"
                    :state="validateState('nik')"
                    v-if="form.userType === 'sso'"
                  />

                  <b-form-input
                    v-else-if="form.userType === 'nonsso'"
                    size="sm"
                    id="nik"
                    type="text"
                    placeholder="Enter Text..."
                    v-model="$v.form.nik.$model"
                    :state="validateState('nik')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nik_feedback">
                    Nik Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="name_id_label"
                  label="Nama :"
                  label-for="name"
                  v-if="form.userType === 'nonsso'"
                >
                  <b-form-input
                    size="sm"
                    id="name"
                    type="name"
                    placeholder="Enter Text..."
                    v-model="$v.form.name"
                    :state="validateState('name')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="name_feedback">
                    Nama Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="password_id_label"
                  label="Password :"
                  label-for="password"
                  v-if="form.userType === 'nonsso'"
                >
                  <b-form-input
                    size="sm"
                    id="password"
                    type="password"
                    placeholder="Enter Text..."
                    v-model="$v.form.password"
                    :state="validateState('password')"
                    autocomplete="new-password"
                  ></b-form-input>

                  <b-form-invalid-feedback id="password_feedback">
                    Password Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="pabrik_id_label"
                  label="Pabrik/Lokasi :"
                  label-for="pabrik_id"
                >
                  <b-form-select
                    size="sm"
                    id="pabrik"
                    v-model="$v.form.pabrik_id.$model"
                    :options="pabrik"
                    :state="validateState('pabrik_id')"
                  ></b-form-select>

                  <b-form-invalid-feedback id="pabrik_id_feedback">
                    Pabrik/Lokasi Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="akses_label"
                  label="Akses:"
                  label-for="akses"
                >
                  <treeselect
                    size="sm"
                    id="fitur"
                    v-model="$v.form.fitur.$model"
                    :multiple="true"
                    :clearable="true"
                    :options="akses"
                    valueFormat="label"
                    :state="validateState('fitur')"
                  />

                  <b-form-invalid-feedback id="akses_feedback">
                    Akses Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/user/users')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

const simulateAsyncOperation = fn => {
  setTimeout(fn, 500)
}

export default {
  mixins: [validationMixin],
  name: 'addrole',
  data() {
    return {
      form: {
        nama_role: null,
        pabrik_id: null,
        fitur: [],
        userType: 'sso',
      },
      users: [],
      pabrik: [],
      akses: [],
      // jabatan: [],

      loading: false,
    }
  },
  validations: {
    form: {
      nik: {
        required,
      },
      pabrik_id: {
        required,
      },
      name: {
        required: requiredIf(function() {
          return this.form.userType === 'nonsso'
        }),
      },
      password: {
        required: requiredIf(function() {
          return this.form.userType === 'nonsso'
        }),
      },
      fitur: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    Treeselect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'User', route: '/user/users' },
      { title: 'Create Role' },
    ])

    var self = this
    ApiService.get('/user/role/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var role = data.data
          // self.pabrik = pabrik;
          for (let i = 0; i < role.length; i++) {
            self.akses.push({
              id: role[i].id_role,
              label: role[i].nama_role,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          // console.log(pabrik)
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const nik = self.$v.form.nik.$model
      const fitur = self.$v.form.fitur.$model
      const pabrik_id = self.$v.form.pabrik_id.$model
      // const jabatan = self.$v.form.jabatan.$model
      const userType = self.form.userType
      const name = self.$v.form.name.$model
      const password = self.$v.form.password.$model

      // const dataArray = []
      const dataArray = Object.assign([],fitur)
      // console.log(fitur)
      ApiService.post('/user/users/store', {
        nik: nik,
        fitur: dataArray,
        pabrik_id: pabrik_id,
        // jabatan: jabatan,
        userType: userType,
        name: name,
        password: password,
      })
      

        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/user/users')
          }else{ // tambahan untuk log jika ada kesalahan atau error
            console.log(data.message)
            console.log(data.response)
            console.error('Terjadi Kesalahan pada : ', error.response.data)
          }
        })
        .catch(response => {
          console.log(response)
        })
    },

    loadOptionsUsers({ action, searchQuery, callback }) {
      var self = this

      if (action === ASYNC_SEARCH) {
        simulateAsyncOperation(() => {
          ApiService.setHeader()

          ApiService.get('/user/users/searchByNik/' + searchQuery)
            .then(({ data }) => {
              if (data.status == 'ok') {
                //ini yang diubah
                var users = data.data
                //var users = data

                self.users.splice(0, self.users.length)
                // for (let i = 0; i < users.length; i++) {
                self.users.push({
                  id: users[0].no_badge,
                  label: users[0].no_badge + ' (' + users[0].nama + ')',
                })
                // }
              }
            })
            .catch(response => {
              console.log(response)
            })

          callback(null, self.users)
        })
      }
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
